
import { defineComponent, nextTick } from 'vue'
import { Swiper as SwiperContainer, SwiperSlide } from 'swiper-vue2'
import 'swiper/swiper-bundle.css'
import { Component } from 'vue/types/umd'
import LinkWithArrow from '../blocks/LinkWithArrow.vue'

export default defineComponent({
  components: {
    SwiperContainer,
    SwiperSlide,
    LinkWithArrow
  },
  props: {
    gap: {
      type: Number,
      default: 30
    },
    linkHref: {
      type: String,
      default: '#'
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    scrollBtns: {
      type: Boolean,
      default: true
    },
    noHeaderWrap: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      required: true
    },
    slidesPerView: {
      type: [Number, String],
      default: 3
    },
    paintingSlider: {
      type: Boolean,
      default: false
    },
    component: {
      type: Object as () => Component
    }
  },
  data () {
    return {
      swiper: null as any,
      isEnd: false,
      isBeginning: true,
      slides: 'auto'
    }
  },
  watch: {
    '$route.query': {
      handler () {
        this.updateSwiper()
      }
    },
    swiper () {
      this.updateSwiperState() // Устанавливаем начальное состояние
    }
  },
  mounted () {
    nextTick().then(() => {
      this.updateSwiperState() // Устанавливаем начальное состояние
      // Подписка на события Swiper
      this.swiper.on('slideChange', this.updateSwiperState)
      this.swiper.on('reachEnd', () => (this.isEnd = true))
      this.swiper.on('reachBeginning', () => (this.isBeginning = true))
      setTimeout(() => {
        this.updateSwiperState() // Устанавливаем начальное состояние
      }, 200)
    })
  },
  methods: {
    updateSwiperState () {
      this.isEnd = this.swiper.isEnd
      this.isBeginning = this.swiper.isBeginning
    },
    updateSwiper () {
      this.swiper.update()
    },
    next () {
      this.swiper.slideNext()
      this.isEnd = this.swiper.isEnd
      this.isBeginning = this.swiper.isBeginning
    },
    prev () {
      this.swiper.slidePrev()
      this.isEnd = this.swiper.isEnd
      this.isBeginning = this.swiper.isBeginning
    },
    handleWheel (ev: WheelEvent) {
      if (ev.deltaY < 0) {
        this.prev()
      } else {
        this.next()
      }
    },
    onSwiper (swiperOn: any) {
      this.swiper = swiperOn
    }
    // updateSlidesPerView () {
    //   const swiperContainer = this.swiper.el
    //   const slides = this.swiper.el.querySelectorAll('.swiper-slide')
    //   const paintingSlides = this.swiper.el.querySelectorAll('.painting-card__image')

    //   if (slides.length === 0) { return 1 } // Если слайдов нет, показываем 1

    //   // Получаем массив ширины всех слайдов
    //   const slideWidths = Array.from(paintingSlides.length > 0 ? paintingSlides : slides).map(slide => slide.offsetWidth)

    //   // Можно использовать среднюю ширину, минимальную или максимальную
    //   // const avgSlideWidth = slideWidths.reduce((a, b) => a + b, 0) / slideWidths.length
    //   // const minSlideWidth = Math.min(...slideWidths)
    //   const maxSlideWidth = Math.max(...slideWidths)
    //   console.log(maxSlideWidth, 'avgSlideWidth')

    //   // Выбираем, какой способ использовать
    //   const slideWidth = maxSlideWidth + 30 // Можно заменить на minSlideWidth или maxSlideWidth

    //   // Вычисляем, сколько слайдов помещается в контейнер
    //   const containerWidth = swiperContainer.clientWidth
    //   console.log(containerWidth, 'containerWidth')
    //   console.log(slideWidth, 'slideWidth')

    //   this.slides = Math.max(1, Math.floor(containerWidth / slideWidth)) // Минимум 1 слайд
    // }
  }
})
